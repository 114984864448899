import { Page } from "@app/site";
import { Spacer } from "@app/ui";
import { SEO } from "@app/ui/seo";
import { PageProps } from "gatsby";
import React from "react";

function Privacy({}: PageProps) {
  return (
    <Page>
      <SEO title="DeckedLink - Privacy Policy" />
      <Spacer h={"500px"} />
      <iframe
        style={{
          height: "100%",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
        }}
        src={process.env.GATSBY_PRIVACY_PAGE_LINK}
        loading="eager"
      ></iframe>
    </Page>
  );
}

export default Privacy;
